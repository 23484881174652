<template>
  <div>
    <span class="text-center table-edit-icon" @click="getPreview()">
      <i class="text-center material-icons">remove_red_eye</i>
    </span>
    <div class="demo-alignment">
      <vs-popup
        v-if="userDetail.length > 0"
        title="User Details"
        :active.sync="popupActivo"
      >
        <div class="vx-col w-full">
          <div class="mx-0 row mb-6">
            <div class="col-12 col-md-6">
              <p class="text-muted small mb-1">Name:</p>
              <h5>{{ userDetail[0].user_name || "-" }}</h5>
            </div>

            <div class="col-12 col-md-6">
              <p class="text-muted small mb-1">Email:</p>
              <h5 class="text-break">{{ userDetail[0].user_email || "-" }}</h5>
            </div>
          </div>
          <div class="mx-0 row mb-6">
            <div class="col-12 col-md-6">
              <p class="text-muted small mb-1">Address:</p>
              <h5>{{ userDetail[0].user_address || "-" }}</h5>
            </div>

            <div class="col-6">
              <p class="text-muted small mb-1">Phone:</p>
              <h5>{{ userDetail[0].user_mobile || "-" }}</h5>
            </div>
          </div>
          <div class="mx-0 row mb-6">
            <div class="col-12 col-md-6">
              <p class="text-muted small mb-1">Gender:</p>
              <h5>{{ userDetail[0].user_gender || "-" }}</h5>
            </div>

            <div class="col-12 col-md-6">
              <p class="text-muted small mb-1">Date of Birth:</p>
              <h5>{{ userDetail[0].user_dob || "-" }}</h5>
            </div>
          </div>
        </div>
        <h3 class="text-center mb-4">-Employment Details-</h3>
        <div
          class="vx-col w-full border-bottom mb-6"
          v-for="(empDetails, epmIndex) in userEmpDetails"
          :key="epmIndex"
        >
          <div class="mx-0 row mb-6">
            <div class="col-12 col-md-6">
              <p class="text-muted small mb-1">Company Name:</p>
              <div>
                <h5 v-if="epmIndex == 0" style="color: red">
                  {{ empDetails.company_name || "-" }}
                </h5>
                <h5 v-else>
                  {{ empDetails.company_name || "-" }}
                </h5>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <p class="text-muted small mb-1">Designation Name:</p>
              <div>
                <h5 v-if="epmIndex == 0" style="color: red">
                  {{ empDetails.designation_name || "-" }}
                </h5>
                <h5 v-else>{{ empDetails.designation_name || "-" }}</h5>
              </div>
            </div>
          </div>
          <div class="mx-0 row">
            <div class="col-12 col-md-6">
              <p class="text-muted small mb-1">Department Name:</p>
              <div>
                <h5 v-if="epmIndex == 0" style="color: red">
                  {{ empDetails.department_name || "-" }}
                </h5>
                <h5 v-else>{{ empDetails.department_name || "-" }}</h5>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <p class="text-muted small mb-1">Role Name:</p>
              <div>
                <h5 v-if="epmIndex == 0" style="color: red">
                  {{ empDetails.role_name || "-" }}
                </h5>
                <h5 v-else>{{ empDetails.role_name || "-" }}</h5>
              </div>
            </div>
          </div>
        </div>
      </vs-popup>
      <!-- <vs-popup
        v-if="userDetail.length > 0"
        :id="'popmodalView' + params.data.user_id"
        title="User Details1"
        :active.sync="popupActive"
      >
       
      </vs-popup> -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import UserService from "@/services/UserService.js";
import { error } from "util";
import moment from "moment";
export default Vue.extend({
  data() {
    return {
      userDetail: [],
      params: null,
      singleUserId: "",
      userEmpDetails: [],
      popupActive: false,
      popupActivo: false,
      //   project_id: ""
    };
  },
  components: {
    moment,
  },

  beforeMount: function() {
    // this.value = this.params.data;
    // this.singleUserId = this.value.user_id;
    this.user_id = this.params.value;
  },
  // beforeDestroy() {
  //   let _id = document.getElementById(
  //     "popmodalView" + this.params.data.user_id
  //   );
  //   _id.setAttribute("style", "display: none;");
  // },
  filters: {
    moment: function(date) {
      if (date == "" || date == null) {
        return "-";
      } else {
        return moment(date).format("DD-MM-YYYY");
      }
    },
  },

  methods: {
    getPreview: function() {
      this.popupActivo = true;
      UserService.getSingleUser(
        this.user_id,
        String(this.params.data.user_active) === "4" ? "archive" : undefined
      )
        .then((response) => {
          const { data } = response;
          if (!data.err) {
            this.userDetail = data.data;

            console.log(this.userDetail, "jj");
            this.userEmpDetails = this.userDetail[0].Employment.reverse();
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
  },
});
</script>
